import React, { useState } from "react";
import AlertCard from "../AlertCard";

function AlertCardBankruptcy() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const alertNews008 = (
    <li className="alertCard eight">
      <div className="cardText">
        <h3>
          Pierwsze ugody w&nbsp;Getin Noble Banku zawarte. Syndyk zapowiada
          propozycje ugodowe dla kolejnych grup klientów
        </h3>
        <br></br>
        <p className="lead">
          <span className="bold">
            Realizacja Programu Ugód dla kredytobiorców upadłego Getin Noble
            Banku nabiera tempa. Zostały zawarte pierwsze ugody, rozpoczęło się
            także wystawianie listów mazalnych. Zgodnie z&nbsp;zapowiedziami
            bank otworzy niedługo kolejne etapy Programu, które pozwolą
            z&nbsp;niego skorzystać jeszcze większej ilości klientów.
          </span>
        </p>

        {!isExpanded && (
          <a
            className="readMore"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleToggle();
            }}
          >
            Czytaj więcej
          </a>
        )}
        {isExpanded && (
          <>
            <br></br>
            <p>
              Od początku postępowania upadłościowego Getin Noble Banku syndyk
              Marcin Kubiczek deklarował, że chciałby możliwie dużo postępowań
              z&nbsp;udziałem upadłego banku zakończyć w drodze ugody.
              <span className="italic">
                {" "}
                – Zależy mi na polubownym zakończeniu jak największej liczby
                sporów sądowych. Przygotowaliśmy więc Program Ugód dla
                konsumentów, którzy wzięli kredyt na cele mieszkaniowe. Warunki
                zostały zaprojektowane optymalnie pod kątem zabezpieczenia
                klientów banku, jego wierzycieli i&nbsp;Skarbu Państwa –{" "}
              </span>
              mówi syndyk Getin Noble Bank&nbsp;S.A. w&nbsp;upadłości Marcin
              Kubiczek.
            </p>
            <p>
              Program wystartował kilka miesięcy temu, a&nbsp;już dziś można
              odnotować jego pierwsze efekty, w&nbsp;tym zawarte ugody,
              a&nbsp;także wystawianie listów mazalnych. Na ten moment
              procedowanych jest ok. 1000 spraw nadających się do zawarcia
              ugody, z&nbsp;czego w&nbsp;60 przypadkach warunki zostały
              uzgodnione, więc bank czeka tylko na podpis kredytobiorców pod
              porozumieniem.
            </p>
            <p>
              W&nbsp;procesie zawierania ugód przedstawiciele syndyka są
              w&nbsp;stałym kontakcie m.in. z&nbsp;kancelariami
              i&nbsp;stowarzyszeniami, które reprezentują frankowiczów
              kwalifikujących się do udziału w&nbsp;Programie. W&nbsp;przypadku
              dwóch największych kancelarii łącznie mowa o&nbsp;prawie 700
              klientach, którzy spełniają warunki ugody i&nbsp;są nią
              zainteresowani.
            </p>
            <p>
              Wypracowanie programu ugód dla klientów upadłego Getin Noble Banku
              było efektem konsultacji z&nbsp;różnymi interesariuszami:
              <span className="italic">
                {" "}
                – Realizacja Programu Ugód nabiera tempa z&nbsp;tygodnia na
                tydzień –{" "}
              </span>
              mówi syndyk Marcin Kubiczek.
              <span className="italic">
                {" "}
                – Codziennie przyjmujemy zgłoszenia od kredytobiorców lub ich
                pełnomocników. Dodatkowo wysłaliśmy ponad 260 pism procesowych
                do spraw sądowych pozostających w&nbsp;toku wraz
                z&nbsp;projektem skonkretyzowanej ugody sądowej. Bierzemy też
                udział w posiedzeniach pojednawczych – od początku roku było ich
                prawie 800 –{" "}
              </span>
              podsumowuje.
            </p>
            <p>
              Aktualnie kształt Programu Ugód pozwala na dołączenie tylko tym
              kredytobiorcom, którzy są konsumentami i&nbsp;spłacili co najmniej
              200% kapitału. Na takie warunki zgodził się prowadzący
              postępowanie upadłościowe Sędzia-Komisarz, co jest niezbędnym
              elementem całego procesu. Od samego początku oczywistym było, że
              w&nbsp;miarę postępu prac związanych z&nbsp;wyliczeniami
              ekonomicznych założeń do Programu, kredytobiorcom konsumentom
              zostaną zaoferowane kolejne etapy, nad którymi równolegle trwają
              prace. Zgodnie z&nbsp;publicznymi deklaracjami syndyk GNB Marcin
              Kubiczek w&nbsp;najbliższych tygodniach przewiduje modyfikację
              wymogów formalnych tak, aby jak najmocniej rozszerzyć grupy
              klientów, którzy mogliby skorzystać z&nbsp;Programu.
            </p>
            <p>
              Zawarcie ugód będzie skutkowało umorzeniem dużej części postępowań
              sądowych wytoczonych bankowi. To oznacza ograniczenie kosztów,
              które wszystkie strony ponoszą w związku z&nbsp;ich prowadzeniem:
              <span className="italic">
                {" "}
                – Od początku zapowiadaliśmy, że docelowo Program Ugód ma objąć
                wszystkie grupy kredytobiorców, więc już niedługo będziemy mogli
                złożyć kolejny wniosek do Sędziego-Komisarza o&nbsp;wydanie
                zezwolenia na kolejny etap Programu. Nastąpi to, gdy tylko
                sfinalizujemy proces analiz (w&nbsp;tym wycenę portfela) –{" "}
              </span>
              deklaruje Marcin Kubiczek.
            </p>
            <a
              className="readMore"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleToggle();
              }}
            >
              Zwiń
            </a>
          </>
        )}
      </div>
      <p className="cardDate">Opublikowano 08.04.2025&nbsp;r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={alertNews008} />
    </>
  );
}

export default AlertCardBankruptcy;
