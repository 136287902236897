import React from "react";
import { Link } from "react-router-dom";
import "../css/Bankruptcy.css";
import Accordion from "../components/Accordion";
import { useScrollReveal } from "../hooks/useScrollReveal";

import AlertNews009 from "../components/AlertsAndNews/AlertNews009";
import AlertNews010 from "../components/AlertsAndNews/AlertNews010";
import AlertNews011 from "../components/AlertsAndNews/AlertNews011";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationArrow,
} from "@fortawesome/fontawesome-free-solid";

import doc01 from "../doc/bankruptcy_docs/instrukcja_1.pdf";
import doc02 from "../doc/bankruptcy_docs/obwieszczenie_1.pdf";
import doc03 from "../doc/bankruptcy_docs/objasnienie_1.pdf";
import doc04 from "../doc/bankruptcy_docs/zawiadomienie_1.pdf";

function Bankruptcy() {
  const faqContent001 = (
    <p>
      Raty kredytowe należy płacić zgodnie z&nbsp;obowiązującym harmonogramem.
      <br></br>
      <br></br>
      Informacje o tabelach kursowych otrzymują Państwo SMSem oraz pozostają
      dostępne w&nbsp;portalu informacyjnym po zalogowaniu się.
      <br></br>
      <br></br>
      Raty należy płacić na aktualny rachunek. W&nbsp;wypadku zmiany rachunku
      będą Państwo o&nbsp;tym osobno informowani
    </p>
  );

  const faqContent002 = (
    <p>
      Gen Noble Bank&nbsp;S.A. działa dalej, ale z&nbsp;dopiskiem
      w&nbsp;„upadłości”. Wnioski o&nbsp;wydanie zaświadczeń o&nbsp;dokonanych
      spłatach oraz inną{" "}
      <span className="underline">
        korespondencje bieżącą należy kierować do Centrali Banku: ul.&nbsp;Rondo
        Daszyńskiego&nbsp;2C, 00-843 Warszawa. Dane teleadresowe dostępne pod
        adresem{" "}
        <Link className="gold" to="/kontakt">
          www.gnbbank.pl/kontakt
        </Link>
      </span>
      .<br></br>
      <br></br>
      Jednocześnie wskazujemy, że dołączenie zaświadczenia o&nbsp;dokonanych
      spłatach nie jest warunkiem prawnym skuteczności zgłoszenia
      wierzytelności. Dokument ten może być uzupełniony później w&nbsp;ramach
      uruchomionej procedury zgłoszenia wierzytelności.
    </p>
  );

  const faqContent003 = (
    <p>
      Nie. Ogłoszenie upadłości nie modyfikuje harmonogramu spłat rat
      kredytowych. W&nbsp;szczególności ogłoszenie upadłości nie powoduje
      przyspieszenia płatności rat kredytowych.
      <br></br>
      <br></br>Sytuacja kredytobiorców spłacających kredyt zgodnie
      z&nbsp;harmonogramem nie może ulec pogorszeniu.
    </p>
  );

  const faqContent004 = (
    <p>
      Tak. Zespół syndyka pracuje nad rozwiązaniami i&nbsp;propozycjami dla
      wierzycieli, w&nbsp;tym kredytobiorców „frankowych”. Upadłość Gen Noble
      Bank&nbsp;S.A. ma charakter precedensowy i&nbsp;wymaga wielopłaszczyznowej
      analizy, w&nbsp;tym zasad wynikających z&nbsp;prawa upadłościowego.
      <br></br>
      <br></br>Po wypracowaniu modelu rozwiązań i&nbsp;uzyskaniu niezbędnych
      zgód (jeżeli okażą się konieczne), Syndyk wystąpi z&nbsp;propozycją
      rozwiązań do kredytobiorców.
    </p>
  );

  const faqContent005 = (
    <p>
      Zgodnie z&nbsp;Prawem upadłościowym ostatecznie o&nbsp;kierunku likwidacji
      masy upadłości (w&nbsp;tym wierzytelności kredytowych) decyduje Sędzia–
      Komisarz na wniosek syndyka.<br></br>
      <br></br>Obecnie nie jest intencją syndyka sprzedaż portfela
      wierzytelności niewymagalnych (obsługiwanych). W&nbsp;tym zakresie
      w&nbsp;ramach obowiązujących uregulowań istnieją odpowiednie standardy.
      Przedmiotowych ograniczeń nie trzeba stosować do portfela wierzytelności
      wymagalnych (kredytów wypowiedzianych).<br></br>Z&nbsp;tych przyczyn
      istotnym jest, aby nie zaszły przesłanki do wypowiedzenia kredytu przez
      Gen Noble Bank&nbsp;S.A. w&nbsp;upadłości, to jest by harmonogram rat był
      realizowany.
    </p>
  );

  const faqContent006 = (
    <p>
      Niestety nie. Katalog podmiotów, które mogą wnieść zgłoszenie
      wierzytelności w&nbsp;formie papierowej wyczerpująco wymienia art. 216aa
      ust.&nbsp;1 Prawa upadłościowego. Katalog osób uprawnionych do złożenia
      zgłoszenia wierzytelności w&nbsp;formie papierowej wyczerpująco określa
      art. 216aa ustawy- Prawo upadłościowe. Są to:
      <br></br>
      <br></br>
      <ul className="dottedList">
        <li>
          Osoby posiadające wierzytelność ze stosunku pracy z&nbsp;wyjątkiem
          roszczeń z&nbsp;tytułu wynagrodzenia reprezentanta upadłego lub
          wynagrodzenia osoby wykonującej czynności związane z&nbsp;zarządem lub
          nadzorem nad przedsiębiorstwem.
        </li>
        <li>Osoby posiadające wierzytelności alimentacyjne.</li>
        <li>
          Osoby posiadające wierzytelności z&nbsp;renty z&nbsp;tytułu
          odszkodowania za wywołanie choroby, niezdolności do pracy, kalectwa
          lub śmierci.
        </li>
        <li>
          Osoby posiadające wierzytelności z&nbsp;renty z&nbsp;tytułu zamiany
          uprawnień objętych treścią prawa dożywocia na dożywotnią rentę.
        </li>
      </ul>
      <br></br>Przy czym należności ze stosunku pracy syndyk umieszcza na liście
      wierzytelności także z&nbsp;urzędu (art. 237 prawa upadłościowego).
      <br></br>
      <br></br>
      Zgłoszenie wierzytelności w&nbsp;formie papierowej należy złożyć w Biurze
      Syndyka lub wysłać przesyłką poleconą na adres:
      <br></br>
      <br></br>
      <span className="bold">
        Marcin Kubiczek- Syndyk masy upadłości Getin Noble Bank&nbsp;S.A.
        w&nbsp;upadłości
        <br></br>
        ul. Działkowa&nbsp;8, 41-506&nbsp;Chorzów
      </span>
      <br></br>
      <br></br>Ustawodawca nie przewidział preferencji ze względu na wiek, ani
      nie wyposażył syndyka w&nbsp;dyskrecjonalne uprawnienia do zwalniania
      z&nbsp;obowiązku wnoszenia zgłoszeń wierzytelności za pośrednictwem
      systemu teleinformatycznego KRZ.
    </p>
  );

  const faqContent007 = (
    <p>
      Nie. Przepis art. 236 ust.&nbsp;1 Prawa upadłościowego jest tym względzie
      jasny. Nadto, jeżeli wierzyciel rozważa skorzystanie z&nbsp;przywileju
      potrącenia wedle przepisów prawa upadłościowego (art. 93-96 Prawa
      upadłościowego), dokonanie zgłoszenia wierzytelności jest konieczne.
      <br></br>
      <br></br>Zachęcamy do zgłaszania wierzytelności niezależnie od podstawy
      prawnej i&nbsp;aktualnego statusu sprawy sądowej. Możliwe jest też
      zgłoszenie wierzytelności przez kredytobiorcę, który ma już zamknięty
      kredyt, a&nbsp;nawet wypowiedziany.
      <br></br>
      <br></br>Natomiast istotnym jest, aby podać wyczerpująco wszystkie
      informacje kształtujące sytuację kredytobiorcy, w&nbsp;tym zgodnie
      z&nbsp;treścią art. 240 pkt&nbsp;7) Prawa upadłościowego stan sprawy
      sądowej lub egzekucyjnej z&nbsp;podaniem sygnatury sprawy, stanu sprawy,
      zapadłych wyroków, ewentualnie wydanych postanowień o&nbsp;zabezpieczeniu.
      Z&nbsp;punktu widzenia indywidualizacji sytuacji wierzyciela dołączenie do
      zgłoszenia wierzytelności skany wyroku, postanowień, indywidualnych
      oświadczeń woli jest istotniejsze niż dołączanie umowy kredytowej czy
      innych dokumentów pochodzących od Getin Noble Bank. Wystarczające jest
      powołanie się na te dokumenty.
    </p>
  );

  const faqContent008 = (
    <p>
      Syndyk będzie badał i&nbsp;rozstrzygał zgłoszenia wierzytelności
      w&nbsp;sposób bezstronny- nie może on udzielać porad prawnych
      w&nbsp;zakresie formułowania zgłoszenia wierzytelności i&nbsp;jego
      wnoszenia.
      <br></br>
      <br></br>Informacje ogólne dotyczące składania zgłoszeń wierzytelności
      zostały zamieszczone w&nbsp;zakładce „Objaśnienia do zgłoszeń
      wierzytelności”. Skrupulatność i&nbsp;sumienność w&nbsp;zgłoszeniu
      wierzytelności jest ważniejsza niż pośpiech. Należy pamiętać
      o&nbsp;podstawowych kwesach.
      <br></br>
      <br></br>
      <ul className="dottedList">
        <li>
          {" "}
          Do zgłaszania wierzytelności służy w&nbsp;systemie teleinformatycznym
          Krajowego Rejestru Zadłużonych specjalny formularz o&nbsp;numerze
          30048 [ścieżka wyboru formularza w systemie teleinformatycznym KRZ:
          Katalog dokumentów / Upadłość przedsiębiorcy / Pisma
          w&nbsp;postępowaniu po ogłoszeniu upadłości / Pisma związane
          z&nbsp;listą wierzytelności]. Nie należy składać pism
          i&nbsp;oświadczeń związanych ze zgłoszeniem do akt głównych,
          w&nbsp;tym przy wykorzystaniu formularza nr&nbsp;70008.
        </li>
        <li>
          Wskazanie błędnej kategorii wierzytelności nie dyskwalifikuje
          zgłoszenia wierzytelności. Syndyk będzie z&nbsp;urzędu badał właściwą
          kategorię wierzytelności.
        </li>
        <li>
          W&nbsp;zgłoszeniu wierzytelności należy powołać [wskazać] na dokumenty
          i&nbsp;dowody potwierdzające zgłaszaną wierzytelność co do zasady
          i&nbsp;wysokości. Nie ma bezwzględnego wymogu dołączania wszystkich
          dokumentów [dopuszczalna pojemność plików jest z&nbsp;góry narzucana
          przez system teleinformatyczny KRZ]. Syndyk może na późniejszym
          etapie, przy zastosowaniu art. 243 ust.&nbsp;2 Prawa upadłościowego
          wezwać do przedłożenia dalszych dokumentów, czy wyjaśnień.
          W&nbsp;pierwszym rzędzie najlepiej dołączyć dokumenty
          indywidualizujące sytuację wierzyciela, takich jak wyroki,
          postanowienia, materialnoprawne oświadczenia woli, niż samą treść
          umowy kredytowej (można powołać się na jej numer w&nbsp;uzasadnieniu
          zgłoszenia wierzytelności).
        </li>
        <li>
          Z&nbsp;uwagi na ograniczenia systemu teleinformatycznego warto
          rozważyć dołączenie w&nbsp;formie osobnego pliku pisma zawierającego
          wszystkie informacje przewidziane w&nbsp;art. 240 Prawa
          upadłościowego, nawet w&nbsp;formie i&nbsp;układzie klasycznego pisma
          procesowego. Warto rozważyć zawarcie w&nbsp;takim piśmie zwięzłego
          uzasadnienia stanowiska wierzyciela. Można w&nbsp;nim przedstawić
          swoją optykę sprawy i&nbsp;argumentację, a&nbsp;nawet przedstawić
          zgłoszenie wierzytelności w formie „roszczeń ewentualnych” (np.: obok
          twierdzeń o&nbsp;nieważności także przedstawić roszczenie
          o&nbsp;„odfrankowienie”). Można także zawrzeć niewiążącą gotowość do
          zawarcia „ugody” lub innego porozumienia z&nbsp;masą upadłości.
        </li>
      </ul>
    </p>
  );

  const faqContent009 = (
    <p>
      Zgłoszenie wierzytelności i&nbsp;uczestnictwo w&nbsp;postępowaniu
      upadłościowym nie jest obwarowane tzw. „przymusem adwokacko- radcowskim”.
      Krąg podmiotów uprawnionych do bycia pełnomocnikiem w&nbsp;postepowaniu
      upadłościowym jest określony art. 87 §&nbsp;1&nbsp;Kodeksu postępowania
      cywilnego.<br></br>
      <br></br>Warto rozważyć skorzystanie z&nbsp;zastępstwa procesowego
      zawodowego pełnomocnika, którym obok adwokata i&nbsp;radcy prawnego,
      w&nbsp;postępowaniu upadłościowym, może być też osoba posiadająca licencję
      doradcy restrukturyzacyjnego.<br></br>
      <br></br>Zastępstwo procesowe ma wymiar praktyczny i&nbsp;prawny.<br></br>
      <br></br>Zawodowy pełnomocnik powinien posiadać lub mieć możliwość
      szybkiego założenia konta użytkownika zarejestrowanego, za pośrednictwem,
      którego może przy wykorzystaniu formularza o&nbsp;numerze 30048
      w&nbsp;imieniu Mocodawcy dokonać zgłoszenia wierzytelności i&nbsp;dopełnić
      wszystkich dalszych czynności w&nbsp;interesie Mocodawcy. Zawodowy
      pełnomocnik na zasadzie przewidzianej w&nbsp;art. 216a ust. 1c-1e Prawa
      upadłościowego ma możliwość poświadczania elektronicznych kopii
      załączanych dokumentów, co może uchronić od potrzeby ich składania w
      oryginale lub poświadczonych odpisach.<br></br>
      <br></br>Zastępstwo zawodowego pełnomocnika i&nbsp;dokonanie za jego
      pośrednictwem wszystkich czynności formalnych i&nbsp;materialnych
      związanych ze zgłaszaniem wierzytelności, może uchronić od przewidzianych
      prawem upadłościowym i&nbsp;procedurą cywilną, rygorów niedochowania
      wymogów formalnych i&nbsp;materialnych.<br></br>
      <br></br>Sytuacja każdego indywidualnego kredytobiorcy może być różna
      w&nbsp;zależności np.: od stopnia zaawansowania spłaty kredytu albo etapu
      prowadzonego procesu sądowego na chwile ogłoszenia upadłości. Zawodowy
      pełnomocnik może udzielić porady prawnej w&nbsp;zakresie optymalnego
      skonstruowania zgłoszenia wierzytelności, może wyjaśnić możliwość
      formułowania roszczeń ewentualnych (np.: o&nbsp;tzw. „odfrankowienie”).
      Może też udzielić porady i&nbsp;wyjaśnień co do zasad prawnych zawierania
      ugody lub porozumienia z&nbsp;masą upadłości.<br></br>
      <br></br>W wypadku, gdyby w&nbsp;związku ze zgłoszeniem wierzytelności
      miały być składane także oświadczenia materialnoprawne, warto zadbać, aby
      pełnomocnictwo zawierało stosowne umocowanie także w&nbsp;tym zakresie.
    </p>
  );

  // const scrollToSection = (id) => {
  //   const section = document.getElementById(id);
  //   if (section) {
  //     section.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  useScrollReveal({ selector: "#bankruptcy" });
  useScrollReveal({
    selector: ".container",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="bankruptcy">
      <section id="newAlerts">
        <div className="container">
          <header className="sectionHeader">
            <h2>Aktualności</h2>
          </header>
          <ul className="cards">
            <AlertNews011 />
            <AlertNews010 />
            <AlertNews009 />
          </ul>
          <div className="goToAlerts">
            <Link to="/informacje-o-postepowaniu/aktualnosci">
              <span>Zobacz więcej</span>
              <i class="fa-solid fa-chevron-right"></i>
            </Link>
          </div>
        </div>
      </section>

      <section id="information">
        <div className="container">
          <header className="sectionHeader">
            <h2>Informacje o&nbsp;postępowaniu</h2>
          </header>
          <h3>
            Informacja wstępna w przedmiocie ogłoszenia upadłości dłużnika
            w&nbsp;osobie GETIN NOBLE BANK&nbsp;S.A. w&nbsp;upadłości
            z&nbsp;siedzibą w&nbsp;Warszawie
          </h3>
          <p>
            Postanowieniem z&nbsp;dnia 20.07.2023 r. Sąd Rejonowy dla m.st.
            Warszawy w&nbsp;Warszawie XVIII Wydział Gospodarczy dla spraw
            upadłościowych i&nbsp;restrukturyzacyjnych, ogłosił upadłość GETIN
            NOBLE BANK S.A. w&nbsp;upadłości z&nbsp;siedzibą w&nbsp;Warszawie
            (dalej: „Upadły”), stwierdzając, że&nbsp;funkcję Sędziego komisarza
            będzie pełnił sędzia, funkcję zastępcy Sędziego komisarza będzie
            pełnił sędzia, oraz wyznaczył syndyka masy upadłości w&nbsp;osobie
            Marcina Mirosława Kubiczka [numer licencji 244]. Sąd upadłościowy
            wezwał wierzycieli upadłego do zgłoszenia wierzytelności syndykowi
            za&nbsp;pośrednictwem systemu teleinformatycznego obsługującego
            postępowanie sądowe, w&nbsp;terminie trzydziestu dni od dnia
            obwieszczenia postanowienia o&nbsp;ogłoszeniu upadłości
            w&nbsp;Rejestrze; dla wierzycieli, o&nbsp;których mowa w art. 216aa
            ust. 1 ustawy z&nbsp;dnia 28 lutego 2003&nbsp;r. Prawo upadłościowe,
            wskazał adres do&nbsp;zgłoszenia wierzytelności syndykowi: ul.
            Działkowa&nbsp;8, 41-506 Chorzów. Sąd upadłościowy wezwał osoby,
            którym przysługują prawa oraz prawa i&nbsp;roszczenia osobiste
            ciążące na nieruchomości należącej do upadłego, jeżeli nie zostały
            ujawnione przez wpis w&nbsp;księdze wieczystej, do ich zgłaszania
            syndykowi za&nbsp;pośrednictwem systemu teleinformatycznego
            obsługującego postępowanie sądowe w&nbsp;terminie trzydziestu dni od
            dnia obwieszczenia postanowienia o&nbsp;ogłoszeniu upadłości
            w&nbsp;Rejestrze pod rygorem utraty prawa powoływania się na nie
            w&nbsp;postępowaniu upadłościowym; dla wierzycieli, o&nbsp;których
            mowa w art. 216aa ust. 1 ustawy z&nbsp;dnia 28 lutego 2003&nbsp;r.
            Prawo upadłościowe, wskazał adres do zgłoszenia praw oraz praw
            osobistych i&nbsp;roszczeń ciążących na nieruchomości syndykowi: ul.
            Działkowa&nbsp;8, 41-506 Chorzów.
          </p>
          <p>
            Postępowanie upadłościowe toczy się obecnie pod sygn. akt
            WA1M/GUp/44/2023.
          </p>
          <p>
            Materiały instruktażowe przygotowane przez Centrum Wsparcia
            Krajowego Rejestru Zadłużonych pozostają dostępne pod adresem{" "}
            <a
              className="gold"
              href="https://krz-info-prod.apps.ocp.prod.ms.gov.pl/ext/"
            >
              https://krz-info-prod.apps.ocp.prod.ms.gov.pl/ext/
            </a>
          </p>
          <p>
            Dalszych informacji dotyczących postępowania udziela się pod nr
            telefonu: (+48) 32 308 0000 oraz adresem mailowym{" "}
            <a className="gold" href="mailto:getin@kubiczekm.com">
              getin@kubiczekm.com
            </a>
          </p>
        </div>
      </section>

      <section id="documents">
        <div className="container">
          <header className="sectionHeader">
            <h2>Dokumenty do&nbsp;postępowania</h2>
          </header>
          <ul className="dottedList">
            <li>
              <a href={doc01}>
                Instrukcja dokonania zgłoszenia wierzytelności w&nbsp;formie
                papierowej
              </a>
            </li>
            <li>
              <a href={doc02}>
                Obwieszczenie postanowienia o&nbsp;ogłoszeniu upadłości
              </a>
            </li>
            <li>
              <a href={doc03}>
                Objaśnienia do&nbsp;procedury zgłaszania wierzytelności
              </a>
            </li>
            <li>
              <a href={doc04}>
                Zawiadomienie wierzyciela o&nbsp;ogłoszeniu upadłości
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section id="trusteeContact">
        <div className="container">
          <header className="sectionHeader">
            <h2>Kontakt do biura syndyka</h2>
          </header>

          <ul>
            <ul>
              <h3> Adres do korespondencji:</h3>
              <li>
                Syndyk masy upadłości Getin Noble Bank Spółka Akcyjna
                w&nbsp;upadłości Marcin Kubiczek
              </li>
              {/* <li>
                  <span>ul. Działkowa&nbsp;8, 41-506 Chorzów</span>
                </li> */}
            </ul>
            <ul>
              <li className="flexbox">
                <FontAwesomeIcon icon={faLocationArrow} />
                <a>ul. Działkowa&nbsp;8, 41-506 Chorzów</a>
              </li>

              <li className="flexbox">
                <FontAwesomeIcon icon={faEnvelope} />
                <a className="gold" href="mailto:getin@kubiczekm.com">
                  getin@kubiczekm.com
                </a>
              </li>

              <li className="flexbox">
                <FontAwesomeIcon icon={faPhone} />
                <a
                  className="gold"
                  href="tel:+4832 308 0000"
                  data-external-url="1"
                >
                  +48 32 308 0000
                </a>
              </li>
            </ul>
            <li>
              <p>
                Infolinia czynna od&nbsp;poniedziałku do&nbsp;piątku
                w&nbsp;godzinach 8:00&nbsp;-&nbsp;19:00
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section id="faq">
        <div className="container">
          <header className="sectionHeader">
            <h2>Często zadawne pytania</h2>
          </header>
          <ul className="accordionBox">
            <Accordion
              title="Jak mam regulować raty kredytowe i&nbsp;przeliczać kurs CHF po dniu ogłoszenia upadłości?"
              content={faqContent001}
            />
            <Accordion
              title="Czy upadłość Gen Noble Bank S.A. oznacza zaprzestanie jego działalności? Gdzie mam się zwracać w&nbsp;sprawie wydania zaświadczeń oraz w&nbsp;sprawie bieżącej obsługi kredytu?"
              content={faqContent002}
            />
            <Accordion
              title="Czy upadłość Gen Noble Bank S.A. pogarsza moją sytuację jako kredytobiorcy spłacającego raty?"
              content={faqContent003}
            />
            <Accordion
              title="Czy będzie możliwe zawarcie „ugody” z&nbsp;syndykiem?"
              content={faqContent004}
            />
            <Accordion
              title="Czy syndyk może sprzedać wierzytelność banku „firmie windykacyjnej”? "
              content={faqContent005}
            />
            <Accordion
              title="Czy syndyk może zwolnić mnie z&nbsp;obowiązku dokonania zgłoszenia wierzytelności za pośrednictwem Krajowego Rejestru Zadłużonych z&nbsp;uwagi np.:&nbsp;na wiek?"
              content={faqContent006}
            />
            <Accordion
              title="Czy uprzednie wytoczenie powództwa, uzyskanie wyroku i&nbsp;złożenie oświadczenia o&nbsp;potrąceniu zwalnia mnie z&nbsp;potrzeby dokonania zgłoszenia wierzytelności?"
              content={faqContent007}
            />
            <Accordion
              title="Jak ma wyglądać zgłoszenie wierzytelności?"
              content={faqContent008}
            />
            <Accordion
              title="Czy warto korzystać z&nbsp;zastępstwa procesowego przy zgłoszeniu wierzytelności?"
              content={faqContent009}
            />
          </ul>
        </div>
      </section>
    </main>
  );
}

export default Bankruptcy;
