import React from "react";
import AlertCard from "../components/AlertCard";
import "../css/AlertCard.css";
import { useScrollReveal } from "../hooks/useScrollReveal";

import AlertNews001 from "../components/AlertsAndNews/AlertNews001";
import AlertNews002 from "../components/AlertsAndNews/AlertNews002";
import AlertNews003 from "../components/AlertsAndNews/AlertNews003";
import AlertNews004 from "../components/AlertsAndNews/AlertNews004";
import AlertNews005 from "../components/AlertsAndNews/AlertNews005";
import AlertNews006 from "../components/AlertsAndNews/AlertNews006";
import AlertNews007 from "../components/AlertsAndNews/AlertNews007";
import AlertNews008 from "../components/AlertsAndNews/AlertNews008";
import AlertNews009 from "../components/AlertsAndNews/AlertNews009";
import AlertNews010 from "../components/AlertsAndNews/AlertNews010";
import AlertNews011 from "../components/AlertsAndNews/AlertNews011";

function BankruptcyAlerts() {
  useScrollReveal({ selector: "#alerts" });
  useScrollReveal({
    selector: ".whiteBgc",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main>
      <section id="alerts">
        <div className="container">
          <header className="sectionHeader">
            <h2>Aktualności</h2>
          </header>
          <ul className="cards">
            <AlertNews011 />
            <AlertNews010 />
            <AlertNews009 />
            <AlertNews008 />
            <AlertNews007 />
            <AlertNews006 />
            <AlertNews005 />
            <AlertNews004 />
            <AlertNews003 />
            <AlertNews002 />
            <AlertNews001 />
          </ul>
          <p className="larger">To już wszystkie aktualności.</p>
        </div>
      </section>
    </main>
  );
}

export default BankruptcyAlerts;
